<template>
	<div
		class="wzitem_sub"
		:class="{ wzitem_sub_w: color == 'white' }"
		@click="
			$router.push({
				path: '/gw_detail',
				query: { id: item.id },
			})
		"
	>
		<div
			class="wzitem_sub_Right"
			:class="{
				wzitem_sub_Right_show: item.pic != null && item.pic.length > 0,
			}"
		>
			<img
				class="wzitem_sub_img pointer"
				v-lazy="item.pic"
				:key="item.pic"
				alt=""
			/>
		</div>
		<div
			class="wzitem_sub_Left"
			:class="{ reItem_Left_full: item.pic != null && item.pic.length > 0 }"
		>
			<div>
				<div
					class="wzitem_sub_Left_author"
					:class="{ wzitem_sub_Left_author_w: color == 'white' }"
					>{{ item.author.nickname }}</div
				>
			</div>
			<div
				class="wzitem_sub_Left_title hovercolor pointer"
				:class="{ wzitem_sub_Left_title_w: color == 'white' }"
			>
				{{
					localLanguage == 2
						? item.title
							? item.title
							: item.title
						: item.title
				}}
			</div>
			<div
				class="wzitem_sub_Left_content hovercolor pointer"
				:class="{ wzitem_sub_Left_content_w: color == 'white' }"
			>
				{{
					localLanguage == 2
						? item.illustrate_en
							? item.illustrate_en
							: item.illustrate
						: item.illustrate
				}}
			</div>
			<div
				class="wzitem_sub_Left_time"
				:class="{ wzitem_sub_Left_time_w: color == 'white' }"
				>{{ item.release_time }}</div
			>
		</div>
	</div>
</template>

<script>
import { eventBus } from "./leftTab";

export default {
	props: ["item", "color"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			// 处理收到的通知，并更新组件状态
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {},
};
</script>

<style scoped>
.wzitem_sub {
	display: flex;
	min-height: 140px;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	opacity: 1;
	background: #121212;
	box-sizing: border-box;
	border: 4px solid #0e0e0e;
	box-shadow: inset 0px 0px 23px 0px rgba(0, 0, 0, 0.6);
}
.wzitem_sub_w {
	background: white !important;
	box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.6);
	border: 0px solid #0e0e0e;
}
.wzitem_sub_Left {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.reItem_Left_full {
	width: 100%;
}

.wzitem_sub_Left_author {
	padding: 6px;
	margin-top: 20px;
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: 600;
	line-height: 13px;
	letter-spacing: 0em;
	display: inline-block; /* 使元素的宽度自适应内容 */
	color: #ffffff;
	border-radius: 4px;
	opacity: 1;
	background: #2d2d30;
}

.wzitem_sub_Left_author_w {
	background: #e4e5e8;
	color: #000000;
}

.wzitem_sub_Left_title {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-right: 10px;
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0em;
	font-weight: bold;

	/* 黑色主色（字段一级） */
	color: #ffffff;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;

	font-size: 20px;
}

.wzitem_sub_Left_title_w {
	color: #000000;
}
.wzitem_sub_Left_content {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-right: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;

	/* 正文色/正文辅助色 */
	color: #e1e3f1;
	opacity: 0.7;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.wzitem_sub_Left_content_w {
	color: #000000;
}
.wzitem_sub_Left_time {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-top: 10px;
	margin-bottom: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	color: #b2b2b2;
}

.wzitem_sub_Left_time_w {
	color: #1a1a1a;
}

.wzitem_sub_Right {
	display: none;
}

.wzitem_sub_Right_show {
	display: block;
	margin-right: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-top: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-bottom: clamp(10px, calc(100vw * 10 / 1280), 20px);
	align-items: center;
	align-content: center;
}
.wzitem_sub_img {
	/* width: clamp(140px, calc(100vw * 120 / 1280), 180px); */
	height: clamp(140px, calc(100vw * 120 / 1280), 180px);
	aspect-ratio: 240/140;
	border-radius: 18px;
	object-fit: cover;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.wzitem_sub_Right_show {
		display: block;
		margin-right: 10px;
		margin-left: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.wzitem_sub_img {
		height: 100px;
		border-radius: 18px;
		object-fit: cover;
	}

	.wzitem_sub_Left {
		width: 100%;
		display: contents;
		flex-direction: column;
		justify-content: space-between;
	}

	.wzitem_sub_Left_title {
		-webkit-line-clamp: 5;
		align-content: center;
		margin-top: clamp(6px, calc(100vw * 6 / 414), 10px);
		font-size: clamp(14px, calc(100vw * 16 / 414), 20px);
	}

	.wzitem_sub_Left_content {
		display: none;
		margin-top: clamp(6px, calc(100vw * 6 / 414), 10px);
		font-size: clamp(10px, calc(100vw * 12 / 414), 18px);
	}

	.wzitem_sub_Left_time {
		display: none;
		font-size: clamp(8px, calc(100vw * 10 / 414), 12px);
		margin-top: clamp(6px, calc(100vw * 6 / 414), 10px);
		margin-bottom: clamp(10px, calc(100vw * 10 / 414), 20px);
	}
	.wzitem_sub_Left_author {
		display: none;
		padding: clamp(6px, calc(100vw * 6 / 414), 10px);
		margin-top: clamp(10px, calc(100vw * 10 / 414), 20px);
		font-size: clamp(10px, calc(100vw * 12 / 414), 14px);
	}
}
</style>
